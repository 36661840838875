import Grid from "@mui/material/Grid";
import minebbs from "../images/sponsor/minebbs.png";
import lanko from "../images/sponsor/lanko.png";
import kuniu from "../images/sponsor/kuniu.png";
import duzui from "../images/sponsor/duzui.png";
import imc from "../images/sponsor/imc.re.png";
import hlogofull from "../images/sponsor/hlogofull.png";
import tudoumc from "../images/sponsor/tudoumc.png";
import sute from "../images/sponsor/sute.png";
import chenxi from "../images/sponsor/chenxi.png";
import horain from "../images/sponsor/horain.png";
import dicobot from "../images/sponsor/dicebot.png";
import lanmi from "../images/sponsor/lanmi.png";
import linfeng from "../images/sponsor/linfeng.png";
import huoCould from "../images/sponsor/huocloud.png";
import yuyun from "../images/sponsor/yuyunpng.png";
import wuyouyun from "../images/sponsor/wuyouyun.png";
import gdyy from "../images/sponsor/gdyy.jpg";
import ciyun from "../images/sponsor/ciyun.png";
import devicloud from "../images/sponsor/devicloud.png";
import betamc from "../images/sponsor/betamc.png";
import shunyun from "../images/sponsor/shunyun.svg";
import lanhu from "../images/sponsor/lh.jpg";
import dexunyun from "../images/sponsor/dexunyun.png";
import ecalogo from "../images/sponsor/ecablogo.png";
import zundu from "../images/sponsor/zundu.png";
import jianyuel from "../images/sponsor/JIANYUEL.png";
import kafeizhuji from "../images/sponsor/kafeizhuji.jpg";

interface ITopSponsor {
  img: string;
  link: string;
  style?: React.CSSProperties;
}

function TopSponsor(sponsor: ITopSponsor) {
  return (
    <div
      style={{
        overflow: "hidden",
        margin: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <a target="_black" href={sponsor.link} style={sponsor.style}>
        <img
          src={sponsor.img}
          alt="images"
          max-width="140px"
          height="34px"
          style={{
            borderRadius: "4px",
            filter: "grayscale(20%)",
            ...sponsor.style,
          }}
        />
      </a>
    </div>
  );
}

function DefaultSponsor() {
  const afdShop = "https://afdian.com/item/733e2942965811ed933a5254001e7c00";
  return (
    <div style={{ overflow: "hidden", margin: "8px" }}>
      <a target="_black" href={afdShop}>
        <div
          style={{
            backgroundColor: "#f0f0f0",
            height: "38px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#989898",
            fontSize: "12px",
            margin: "0px 16px",
          }}
        >
          成为金牌赞助商
        </div>
      </a>
    </div>
  );
}

export function GoldSponsor() {
  const topSponsorList = [
    // {
    //   img: mcllxz,
    //   link: "http://www.llxz.cc/",
    // },
    // {
    //   img: mcsmoke,
    //   link: "https://www.mcsmoke.net",
    // },

    {
      img: lanmi,
      link: "https://www.lanmicloud.com",
    },

    {
      img: chenxi,
      link: "http://www.singsi.cn/",
      style: { height: "46px" },
    },
    {
      img: lanhu,
      link: "http://lanhu.xyz:23333/",
      time: "2024/5/8",
    },
    {
      img: minebbs,
      link: "https://www.minebbs.com/",
    },

    {
      img: lanko,
      link: "https://www.lankodata.com/",
    },

    {
      img: jianyuel,
      link: "https://awa.ms",
      time: "2024/7/16",
      style: { height: "30px" },
    },

    {
      img: horain,
      link: "https://www.horain.net/",
    },

    {
      img: sute,
      link: "https://www.suteidc.com",
    },

    {
      img: duzui,
      link: "http://www.duzuii.com/",
    },
    {
      img: linfeng,
      link: "https://www.dkdun.cn/",
      style: { height: "36px" },
    },
    {
      img: kuniu,
      link: "https://www.kuniu.net/",
    },
    {
      img: hlogofull,
      link: "/",
    },
    {
      img: tudoumc,
      link: "https://www.tudoumc.com",
    },
    {
      img: dicobot,
      link: "https://dicebot.cn",
    },
    {
      img: huoCould,
      link: "http://huocloud.com/",
    },
    {
      img: yuyun,
      link: "https://www.rainyun.com/ref=1",
    },
    {
      img: wuyouyun,
      link: "https://www.wuyouyun.com/baremetal/wenzhou2bgp?u=0A81CD",
    },
    {
      img: gdyy,
      link: "https://qyunt.com/",
      style: { height: "30px" },
    },
    {
      img: devicloud,
      link: "https://cloud.devicloud.cn/",
      style: { height: "38px" },
    },
    {
      img: betamc,
      link: "https://betamc.cn/",
      style: { height: "40px" },
    },
    {
      img: shunyun,
      link: "https://www.shunidc.cn/",
      time: "2024/4/25",
      style: { height: "30px" },
    },
    {
      img: ciyun,
      link: "https://www.zovps.com/aff/EQSIAHVJ",
      time: "2024/4/25",
    },
    {
      img: dexunyun,
      link: "https://www.dexunyun.com/HomePage?ad_id=YToxOntzOjI6ImlkIjtpOjI3O30%3D",
      time: "2024/5/27",
      style: { height: "26px" },
    },
    {
      img: ecalogo,
      link: "https://www.cocmorn.cn",
      time: "2024/5/27",
      style: { height: "30px" },
    },
    {
      img: zundu,
      link: "https://www.amagino.com",
      time: "2024/6/25",
      style: { height: "30px" },
    },

    {
      img: kafeizhuji,
      link: "https://pro.cfhost.cn/cart",
      time: "2024/6/25",
      style: { height: "30px" },
    },
  ];

  return (
    <Grid container spacing={0}>
      {topSponsorList.map((v, index) => (
        <Grid item lg={2} md={3} xs={6} key={index}>
          {TopSponsor(v)}
        </Grid>
      ))}
      <Grid item lg={12} md={12} xs={12}>
        <div
          style={{
            textAlign: "center",
            maxWidth: "200px",
            margin: "auto",
            marginTop: "20px",
          }}
        >
          {DefaultSponsor()}
        </div>
      </Grid>
    </Grid>
  );
}

export default GoldSponsor;
